<template>
  <v-theme-provider :dark="dark">
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <base-img
          :src="require('@/assets/Vertical-Dark.png')"
          max-width="350"
          width="100%"
          class="mb-8"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <base-info-card title="Nutrição Moderna" />
        <base-body>
          Conheça meu método de Nutrição Moderna e entenda como ele pode trazer resultados enquanto te permite ter uma alimentação simples e agradável. Não importa quem você seja, se está lendo isso então eu posso te ajudar!
        </base-body>
      </v-col>
    </v-row>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },
  }
</script>
