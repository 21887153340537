<template>
  <v-container
    :class="special ? 'grey lighten-5 rounded elevation-3' : ''"
  >
    <span
      v-if="value < fullValue"
      class="grey--text"
    >
      <s>{{ discount }}</s>
    </span>
    <v-chip
      v-if="special"
      color="red"
      class="ma-2"
      dark
      small
    >
      {{ relativeDiscount }} OFF
    </v-chip>
    <h2 :class="special ? 'red--text' : ''">
      {{ fValue }}
    </h2>
    <div class="grey--text">
      /{{ frequency }} {{ special ? '(adiantado)' : '' }}
    </div>
    <v-btn
      small
      :color="special ? 'green accent-3' : 'success'"
      :href="whatsappLink"
      large
      :block="special"
      target="_blank"
    >
      <v-icon
        left
        small
      >
        mdi-cart
      </v-icon>
      Assinar
    </v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'BasePlanValue',

    props: {
      value: Number,
      fullValue: Number,
      frequency: String,
      info: String,
      special: Boolean,
    },

    computed: {
      fValue: function () {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(this.value)
      },

      discount: function () {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(this.fullValue)
      },

      relativeDiscount: function () {
        return new Intl.NumberFormat('pt-BR', {
          style: 'percent',
        }).format(Math.abs((this.value / this.fullValue) - 0.99))
      },

      whatsappLink: function () {
        return 'https://wa.me/554991483551?text=Olá, tenho interesse no plano *' +
          this.info + '* (' +
          this.frequency + (this.special ? ' - adiantado)' : '') +
          '! Como faço para começar?'
      },
    },
  }
</script>
