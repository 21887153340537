<template>
  <v-row>
    <v-col class="text-center mx-0 px-0">
      <v-btn
        x-large
        :outlined="dark"
        height="72"
        rounded
        class="mt-12"
        :color="dark ? 'white' : 'success'"
        href="#planos"
      >
        <v-icon
          class=" mr-3"
        >
          mdi-cart
        </v-icon>
        Quero ter o acompanhamento<br>nutricional!
      </v-btn>
      <v-row
        align="center"
        justify="center"
      >
        <v-img
          max-width="314"
          max-height="80"
          :src="require(`@/assets/garantia.${dark || darkImg ? 'webp' : 'png'}`)"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BaseBtnPlans',
    props: {
      dark: {
        type: Boolean,
        default: false,
      },
      darkImg: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
