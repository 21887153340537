<template>
  <div>
    <v-row>
      <v-btn
        v-if="href && href.length > 5"
        link
        color="green"
        fab
        :href="href"
        target="_blank"
        align="center"
        justify="center"
        elevation="4"
        large
        class="pa-1"
      >
        <v-avatar
          v-if="src"
          size="56"
        >
          <v-img :src="src" />
        </v-avatar>
      </v-btn>
      <v-avatar
        v-else-if="src"
        size="56"
      >
        <v-img :src="src" />
      </v-avatar>
      <v-col>
        <base-title
          v-if="title"
          :title="title"
          class="text-uppercase"
          size="subtitle-1"
          space="0"
        />

        <base-subtitle
          v-if="subtitle"
          :title="subtitle"
        />
      </v-col>
    </v-row>
    <v-card
      :height="height"
      :width="width"
      class="mb-8"
      flat
      tile
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'BaseCard',

    inheritAttrs: false,

    props: {
      height: [Number, String],
      width: [Number, String],
      subtitle: String,
      title: String,
      src: String,
      href: String,
    },
  }
</script>
