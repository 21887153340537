<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'Home',
    metaInfo: {
      title: 'Nutrição Moderna',
      titleTemplate: '%s | Cassiano Diniz',
      htmlAttrs: { lang: 'pt-BR' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'description',
          vmid: 'description',
          content: 'Nutrição Moderna: Venha viver a Nutrição Moderna e entenda como ela pode trazer resultados enquanto te permite ter uma alimentação prática e prazerosa. Não importa a sua idade ou se você é praticante de exercícios!',
        },
      ],
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
